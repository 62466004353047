<template>
    <div class="container-fluid view edit-profile">
        <div class="sidebar-view">
            <ul>
                <li>
                    <router-link :to="{ name: 'EditProfile' }">{{ $t("Edit Profile") }}</router-link>
                </li>

                <li><a :class="['has-sub-menu', showSubLinks ? 'opened' : '']" @click="subMenuToggler ? subMenuToggler = false : subMenuToggler = true">{{ $t("Edit Resume") }}</a></li>
                <ul v-if="showSubLinks">
                    <li v-for="(item, index) in profileSubLinks" :key="index">
                        <router-link :to="{ name: item.name }">{{ item.title }}</router-link>
                    </li>
                </ul>
            </ul>
        </div>
        <div class="content-view">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>

export default {
    name: "Profile",
    data() {
        return {
            subMenuToggler: false
        }
    },
    computed: {
        profileSubLinks() {
            return [
                {
                    name: 'EditAchievements',
                    title: this.$t('Achievements')
                },
                {
                    name: 'EditCertificates',
                    title: this.$t('Certificates')
                },
                {
                    name: 'EditCourses',
                    title: this.$t('Courses')
                },
                {
                    name: 'EditEducation',
                    title: this.$t('Education')
                },
                {
                    name: 'EditExperience',
                    title: this.$t('Experience')
                },
                {
                    name: 'EditHobbies',
                    title: this.$t('Hobbies')
                },
                {
                    name: 'EditInternship',
                    title: this.$t('Internship')
                },
                {
                    name: 'EditLanguages',
                    title: this.$t('Languages')
                },
                {
                    name: 'EditSkills',
                    title: this.$t('Skills')
                }
            ]
        },
        showSubLinks() {
            if (this.subMenuToggler) {
                return true;
            }
            
            const linkActive = this.profileSubLinks.findIndex(item => item.name == this.$route.name);
            
            if (linkActive != -1) {
                return true;
            }

            return false;
        }
    }
};
</script>